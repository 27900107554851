dt {
  width: 100%;
  max-width: none;
  float: left;
  clear: left;
  position: relative;
}
dt label {
  font-size: 14px;
  line-height: 20px;
  padding-bottom: 3px;
  margin-bottom: 3px;
  display: block;
  width: 100%;
  text-align: left;
  padding-right: 0px;
  font-weight: lighter!important;
}
dt label.required:after {
  content: " * ";
  color: red;
}
dd {
  width: 100%;
  margin: 0;
  padding-left: 0;
  padding-bottom: 14px;
  box-sizing: border-box;
  float: left;
}
dd select {
  padding: 12px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 2px;
  font-size: 16px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  color: inherit;
  appearance: none;
  background-color: transparent;
  background-image: url(../../../assets/img/dropdown.png);
  background-size: 30px auto;
  background-position: center right;
  background-repeat: no-repeat;
}
.inputHolder {
  clear: left;
  float: left;
  display: block;
  width: 100%;
}
.inputItem {
  padding: 12px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 2px;
  font-size: 16px;
  border: 1px solid rgba(0, 0, 0, 0.3);
}
.inputError {
  background-color: white;
  font-size: 12px;
  color: #c91818 !important;
  padding: 5px 0;
}
