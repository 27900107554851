.summary {
  min-width: 100%;
  font-size: 14px;
  background-color: rgba(28, 35, 43, 0.05);
  border-radius: 2px;
  margin-bottom: 20px;
}
.inputItem {
  margin-right: 5px;
  padding: 12px;
  box-sizing: border-box;
  border-radius: 2px;
  font-size: 16px;
  border: 1px solid rgba(0, 0, 0, 0.3);
}
.inputHolder {
  clear: left;
  float: left;
  display: block;
  width: 100%;
}

.inputError {
  background-color: white;
  font-size: 12px;
  color: #c91818 !important;
  padding: 5px 0;
}

@media only screen and (max-width: 500px) {
  .summary {
    font-size: 12px;
  }
}
.summary tr {
  padding: 10px;
}
.summary tr td {
  padding: 10px;
}
.summary tr th {
  line-height: 20px;
  font-weight: normal;
  padding: 10px;
}
.eventName th {
  border-bottom: 1px solid rgba(28, 35, 43, 0.1);
  text-align: left;
}
.name {
  font-weight: bold;
  padding-right: 10px;
}
.date {
  font-weight: normal;
  display: inline-block;
  opacity: 0.7;
}
.ticketRow td {
  padding: 10px 10px 0 10px;
  vertical-align: top;
}
.total td {
  font-weight: bold;
  border-top: 1px solid rgba(28, 35, 43, 0.1);
}

/* form */
.formHolder {
  clear: left;
  margin: 0 -800px 0 -800px;
  padding: 0 800px;
  border-top: 1px solid rgba(28, 35, 43, 0.1);
}
.formHolder2 {
  clear: left;
  margin: 0 -800px 0 -800px;
  padding: 0 800px;
}
.checkoutForm {
  margin: 0 auto;
}
.checkoutForm dl {
  margin: 0;
}
.zendForm {
  overflow: visible;
  clear: left;
}
.inputHolder {
  clear: left;
  float: left;
  display: block;
  width: 100%;
}
/* submit element */
.submitElement {
  text-align: right;
  float: none;
  width: auto;
  display: block;
  border-top: 1px solid rgba(28, 35, 43, 0.1);
  position: relative;
  padding: 20px 40px 10px 40px !important;
  margin: 15px -40px 0 -40px;
}
.zendForm dd {
  box-sizing: border-box;
}
.submitItem {
  background-color: #4d8000;
  color: #ffffff;
  -webkit-appearance: none;
  margin-top: 0;
  padding: 10px 40px;
  border: 0 none;
  cursor: pointer;
  border-radius: 2px;
  font-size: 16px;
  margin-left: 0px;
}
.spr {
  clear: left;
}

/* error box */
.errorMsgTitle {
  color: #c91818 !important;
  margin: 0;
  padding: 5px 0;
  font-size: 14px;
}
.errorMsg {
  color: #c91818 !important;
  text-decoration: underline;
}
.errorNum {
  color: #c91818 !important;
}

/*  test */

/* .inputHolder {
  clear: left;
  float: left;
  display: block;
  width: 100%;
}
.inputItem {
  padding: 12px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 2px;
  font-size: 16px;
  border: 1px solid rgba(0, 0, 0, 0.3);
}
.inputError {
  background-color: white;
  font-size: 12px;
  color: #c91818;
  padding: 5px 0;
} */
dt {
  width: 100%;
  max-width: none;
  float: left;
  clear: left;
  position: relative;
}
dt label {
  font-size: 14px;
  line-height: 20px;
  padding-bottom: 3px;
  margin-bottom: 3px;
  display: block;
  width: 100%;
  text-align: left;
  padding-right: 0px;
  font-weight: lighter !important;
}
dt label.required:after {
  content: " * ";
  color: red;
}
dd {
  width: 100%;
  margin: 0;
  padding-left: 0;
  padding-bottom: 14px;
  box-sizing: border-box;
  float: left;
}
