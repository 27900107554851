.studyBg {
  background-color: var(--studyBg);
  font-family: IranSans !important;
  direction: rtl;
  color: var(--studyColor);
}
.studyHeading {
  margin-top: 5rem;
  margin-bottom: 2.5rem;
  line-height: 1.75rem;
  font-size: 25px;
}
.studyText {
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 300;
  text-align: justify;
}
.contactUs {
  font-size: 25px;
  padding-top: 5rem;
  margin-top: 2.5rem;
  font-weight: 700;
  line-height: 1.75rem;
}
@media only screen and (max-width: 1023px) {
  .topRow {
    width: 83.333333% !important;
  }
  .bottomRow {
    width: 83.333333% !important;
  }
  .textBox {
    width: 83.333333% !important;
  }
}
@media only screen and (max-width: 768px) {
  .topRow {
    width: 93.666667% !important;
  }
  .bottomRow {
    width: 93.666667% !important;
  }
  .textBox {
    width: 93.666667% !important;
  }
}

.input {
  background-color: transparent;
  border-color: rgba(75, 85, 99, 1);
  width: 100%;
  color: var(--studyColor);
  margin-top: 2.5rem;
  margin-bottom: 0.5rem;
  line-height: 1.75rem;
  font-size: 1.125rem;
  border-bottom: 1px solid rgba(75, 85, 99, 1) !important;
  box-shadow: none !important;
}
.input:focus {
  outline: none;
}
.submitBox {
  margin-top: 5rem;
}
.textBlue {
  color: rgba(147, 197, 253, 1);
  cursor: pointer;
}
.submitBtn {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-top: 20px;
  border:1px solid var(--studyColor);
  color :var(--studyColor);
  font-size: 17px;
  border-radius: 9999px;
  background-color: transparent;
}
.submitBtn:hover { 
    background-color:var(--studyColor);
    color:var(--studyBg);
}
