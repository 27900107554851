.investLogo {
  width: 300px;
  height: auto;
  margin-bottom: 10px;
}
.privacyText {
  text-align: justify;
}
.line {
  display: block;
  margin: 0.5em auto;
  border-width: 1px;
  border-style: solid;
}
.cursor {
  cursor: pointer;
}