.swiper-pagination {
  bottom: 13px !important;
}
/* @media only screen and (max-width: 900px) {
  .swiper-pagination {
    bottom: 260px !important;
  }
}
@media only screen and (max-width: 600px) {
  .swiper-pagination {
    bottom: 60px !important;
  }
} */
@media only screen and (max-height: 500px) {
  .swiper-pagination {
    bottom: 20px !important;
  }
}
