.DiaccoInvestBg {
  background-color: var(--investBrown);
  /* height: 100vh !important; */
}
.ContentHolder {
  width: 75%;
  margin: 0 auto;
}
.Banner {
  background-image: url("../../assets//img//invest_banner.png");
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  /* height: 100vh; */
}
.upText {
  text-shadow: -13px 3px 10px #000;
}
.Banner > h1 {
  font-size: 60px;
  line-height: 7vw;
  padding: 5rem 10px 0 11vw;
  color: #fafafa;
  margin-top: 7vw;
  padding-bottom: 7vw;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
@media only screen and (max-width: 991px) {
  .Banner > h1 {
    margin-top: -2vw;
    font-size: 25px;
  }
}
/*@media only screen and (max-width: 525px) {
  .Banner > h1 {
    font-size: 50px;
    margin-top: 7rem;
  }
}
@media only screen and (max-width: 435px) {
  .Banner > h1 {
    font-size: 40px;
  }
}
@media only screen and (max-width: 400px) {
  .Banner > h1 {
    font-size: 35px;
  }
}
@media only screen and (max-width: 450px) {
  .Banner > h1 {
    font-size: 30px;
  }
}
@media only screen and (max-width: 300px) {
  .Banner > h1 {
    font-size: 30px;
    margin-top: 0;
  }
} */
.Description {
  position: relative;
  z-index: 10;
  border-radius: 7px;
  margin: 50px 0;
  border: 5px solid hsla(0, 0%, 98%, 0.19);
  margin: 80px 0 !important;
  background-image: url("../../assets/img/professionals.png");
  width: 100%;
  height: 500px;
  background-size: cover;
  background-position: 50%;
}
.Description > h2 {
  text-align: center;
  background-color: #cf9a63;
  position: absolute;
  left: 50%;
  top: 0;
  font-size: 20px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding: 15px 40px;
  color: #2d2824;
  border-radius: 7px;
}
@media only screen and (max-width: 335px) {
  .Description > h2 {
    text-align: center;
    background-color: #cf9a63;
    position: absolute;
    left: 50%;
    top: -20px;
    font-size: 17px;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    padding: 15px 40px;
    color: #2d2824;
    border-radius: 7px;
  }
}

.Description > div {
  text-align: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  font-size: 18px;
  font-weight: 500;
}

.BuisnessItem {
  margin-top: 100px;
}

.ImageHolder {
  position: relative;
  height: 300px;
}
.ImageHolder > img {
  position: relative;
  top: 2vh;
  height: 100%;
  z-index: 20;
}
@media only screen and (max-width: 991px) {
  .ImageHolder > img {
    top: -10px;
  }
}
@media only screen and (max-width: 360px) {
  .ImageHolder > img {
    height: 70%;
  }
}
.ImageHolder > h4 {
  position: absolute;
  background-color: #cf9a63;
  z-index: 10;
  right: 1vw;
  top: 50px;
  -webkit-transform: translateX(calc(100% - 80px));
  transform: translateX(calc(100% - 80px));
  font-size: 16px;
  padding: 15px 20px 15px 50px;
  border-radius: 7px;
  color: #402c16;
}
@media only screen and (max-width: 1710px) {
  .ImageHolder > h4 {
    right: 0vw;
  }
}
@media only screen and (max-width: 1610px) {
  .ImageHolder > h4 {
    right: -2vw;
  }
}
@media only screen and (max-width: 1475px) {
  .ImageHolder > h4 {
    right: -4vw;
  }
}
@media only screen and (max-width: 1330px) {
  .ImageHolder > h4 {
    right: -7vw;
  }
}
@media only screen and (max-width: 1200px) {
  .ImageHolder > h4 {
    right: -9vw;
  }
}
@media only screen and (max-width: 1100px) {
  .ImageHolder > h4 {
    right: -12vw;
  }
}
@media only screen and (max-width: 991px) {
  .ImageHolder > h4 {
    position: static;
    background-color: transparent;
    -webkit-transform: none;
    transform: none;
    font-size: 24px;
    padding: 0;
    margin: 20px auto;
    border-radius: 7px;
    color: #cf9a63;
    width: 100%;
  }
}
@media only screen and (max-width: 450px) {
  .ImageHolder > h4 {
    font-size: 20px;
  }
}
@media only screen and (max-width: 330px) {
  .ImageHolder > h4 {
    font-size: 17px;
  }
}

.DescriptionParagraph {
  padding: 130px 0 0 30px;
  height: 100%;
  font-size: 18px;
  text-align: justify;
}

@media only screen and (max-width: 1450px) {
  .DescriptionParagraph {
    padding: 130px 0 0 100px;
  }
}
@media only screen and (max-width: 1120px) {
  .DescriptionParagraph {
    padding: 130px 0 0 130px;
  }
}
@media only screen and (max-width: 991px) {
  .DescriptionParagraph {
    padding: 65px 0 0 0;
    text-align: left;
  }
}
@media only screen and (max-width: 360px) {
  .DescriptionParagraph {
    padding: 0;
  }
}

.News > h1 {
  font-size: 23px;
  text-align: center;
}
.FormHolder {
  width: 50%;
  margin: auto;
  margin-top: 80px;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.FormHolder > form > h1 {
  color: rgb(209, 213, 219);
  text-align: center;
  font-weight: 300;
  margin-top: 5rem;
  font-size: 1.25rem;
}
.FormSingle {
  padding: 0.5rem;
}
.FormSingle > textarea {
  margin: 0;
  color: #fff;
  background: transparent;
  width: 100%;
  padding: 0.5rem;
  margin-top: 5px;
  border: 2px solid hsla(0, 0%, 100%, 0.1);
  background-color: hsla(0, 0%, 100%, 0.05);
  border-radius: 12px !important;
  height: 150px;
}

.FormSingle > textarea::-webkit-input-placeholder {
  color: #9ca3af;
  opacity: 0.5;
}
.FormSingle > textarea::-moz-placeholder {
  opacity: 0.5;
  color: #9ca3af;
}
.FormSingle > textarea::-ms-input-placeholder {
  opacity: 0.5;
  color: #9ca3af;
}

.formGroup > div {
  width: 50%;
  float: left;
  padding: 0.5rem;
}
.formGroup > div > input {
  color: #fff;
  border-radius: 12px !important;
  margin-top: 20px;
  background: transparent;
  width: 100%;
  padding: 0.5rem;
  border: 2px solid hsla(0, 0%, 100%, 0.1);
  background-color: hsla(0, 0%, 100%, 0.05);
  border-radius: 12px !important;
}
.formGroup > div > input::-webkit-input-placeholder {
  color: #9ca3af;
  opacity: 0.5;
}
.formGroup > div > input::-moz-placeholder {
  opacity: 0.5;
  color: #9ca3af;
}
.formGroup > div > input::-ms-input-placeholder {
  opacity: 0.5;
  color: #9ca3af;
}
.FormHolder > form > hr {
  margin-top: -5px;
}
.ErrorMessage {
  color: rgb(248, 113, 113);
  font-weight: 400;
}
.FormHolder > form > p {
  margin-top: 10px;
  text-align: center;
  margin-bottom: 0;
}
.DataProcessing {
  color: rgb(251, 191, 36);
  cursor: pointer;
}
.Submit {
  margin: 0 auto;
  margin-top: 15px;
  padding: 0.75rem 2.5rem;
  font-size: 1.125rem;
  color: #c1c1c1;
  border: 0;
  border-radius: 15px;
  transition-duration: 0.4s;
  background-color: #cf9a63;
  box-shadow: 0 3px 30px rgba(208, 155, 100, 0.48);
  color: #fafafa;
}
.Submit:hover {
  box-shadow: none;
}

@media only screen and (max-width: 769px) {
  .formGroup > div {
    width: 100%;
    float: none;
    padding: 0.5rem;
  }
}
@media only screen and (max-width: 1500px) {
  .FormSection > .ContentHolder > .FormHolder {
    width: 90%;
  }
}
