.eventDetailPage {
  min-height: 100%;
  height: auto !important;
  margin: 0 auto -73px;
  color: #ffffff;
  background-color: #000000;
}
.bgBlurWrap {
  height: 385px;
  width: 100%;
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  overflow: hidden;
}
.bGBlur {
  height: 385px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  filter: blur(8px);
}
.blurImg1 {
  background-image: url(../../assets/img/1.png);
}
.blurImg2 {
  background-image: url(../../assets/img/ev-d2.jpg);
}
.blurImg3 {
  background-image: url(../../assets/img/ev-d3.jpg);
}
.detailPage {
  z-index: 2;
  position: relative;
  background-color: var(--mainBlack) !important;
  color: #ffffff !important;
  width: 1024px;
  margin: 0 auto;
  margin-bottom: 20px;
  padding-bottom: 40px;
  /* overflow: hidden; */
}
@media only screen and (max-width: 1024px) {
  .detailPage {
    width: auto;
    margin-bottom: 1px;
  }
}

.header {
  background-color: #242424;
  width: 100%;
  text-align: center;
}
.headerTab {
  background-color: var(--mainBlack);
  padding: 0 4px 4px 4px;
  box-sizing: border-box;
  border-radius: 0 0 6px 6px;
  position: absolute;
  top: 0;
  left: 20px;
  z-index: 3;
  text-align: center;
}
.headerText {
  color: #ffffff;
  text-decoration: none;
  margin: 0;
  padding: 0;
  font-size: 15px;
}
.headerText:hover {
  color: #ffffff;
}
.headerText2 {
  padding: 4px 6px;
  display: inline-block;
}

.eventListHeader {
  width: 100%;
  height: 326px;
  position: relative;
  background-position: top center;
  /* background-image: url("../../assets/img/eventImg.jpg"); */
  background-size: 1024px 326px;
}
.eventImg {
  display: none;
}
.eventTitleBox {
  box-sizing: border-box;
  padding: 20px 40px 0 40px;
  text-align: center;
  word-break: break-word;
}
.eventTitleH2 {
  font-size: 30px;
  font-weight: bold;
  line-height: 40px;
  text-align: inherit;
  margin: 0;
  padding: 0;
}

.timeLoc {
  margin-top: 8px;
}
.eventTimeLoc {
  position: relative;
  padding: 0 0 10px 30px;
  display: inline-block;
  font-size: 18px;
  font-weight: normal;
  margin: 0 2% 0 0;
  opacity: 0.9;
}
.Icon {
  font-size: 1.4em;
  padding-right: 10px;
  opacity: 0.4;
  position: absolute;
  left: 0;
}
.var {
  font-style: normal;
}
.headingBorderLine {
  opacity: 0.3;
  height: 1px;
  width: 100%;
  margin-top: 10px;
  background-color: #ffffff !important;
}
.formItems {
  border: 1px solid rgba(255, 255, 255, 0.4);
  padding: 25px 20px;
  width: 95%;
  margin: 0 auto;
}
.selectTickets {
  margin: 25px 0;
}
.errorAlertBox {
  margin-bottom: 20px;
  border: 0;
  min-height: 30px;
  clear: both;
  padding: 20px 40px;
  box-sizing: border-box;
}
.alertMsg {
  padding: 0pt 10px;
  color: #c91818 !important;
}

/* content */
.contentWraper {
  padding: 0 40px;
}
.leftCol {
  float: left;
  width: 65%;
}

.description {
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
  margin: 30px 0 20px;
  padding: 0;
  text-align: left;
}
.text {
  font-size: 15px !important;
  margin: 0 0 20px 0 !important;
  padding: 0 !important;
  line-height: 25px !important;
  opacity: 0.9 !important;
  word-break: break-word;
}
.title {
  font-size: 18px !important;
}
.locImg {
  height: 450px;
  width: 100%;
}
.locImg img {
  width: 100% !important;
  height: 100% !important;
}
.endBtn {
  color: #ffffff !important;
  width: 22rem;
  border-radius: 4px;
  padding: 14px;
  box-sizing: border-box;
  display: inline-block;
  font-size: 18px;
  font-weight: bold;
  text-align: left;
  color: #ffffff;
  background-color: transparent !important;
  border-color: #ffffff !important;
  margin: 5px 0;
}
@media only screen and (max-width: 375px) {
  .endBtn {
    font-size: 17px;
    width: 20rem;
  }
}
@media only screen and (max-width: 345px) {
  .endBtn {
    font-size: 16px;
    width: 18rem;
  }
}
@media only screen and (max-width: 310px) {
  .endBtn {
    width: 100%;
  }
}
.ticketButton {
  color: #ffffff !important;
  border-radius: 4px;
  padding: 14px;
  box-sizing: border-box;
  display: inline-block;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
  background-color: #af091d !important;
  border-color: transparent !important;
}
.endBtn:hover {
  text-decoration: underline;
}
.ticketButton:hover {
  text-decoration: underline;
}

/* right column */
.rightCol {
  float: right;
  width: 28%;
  position: sticky;
  top: 0px;
}
.tickets {
  background-color: #242424 !important;
  color: #ffffff !important;
  margin-top: 30px;
}
.ticketButton {
  width: 175px;
  background-color: #af091d !important;
  color: #ffffff !important;
  margin-left: 80%;
  margin-top: 10px;
}

@media only screen and (max-width: 1500px) {
  .ticketButton {
    margin-left: 75%;
  }
}
@media only screen and (max-width: 800px) {
  .ticketButton {
    margin-left: 70%;
  }
}
@media only screen and (max-width: 670px) {
  .ticketButton {
    margin-left: 65%;
  }
}
@media only screen and (max-width: 560px) {
  .ticketButton {
    margin-left: 60%;
  }
}
@media only screen and (max-width: 500px) {
  .ticketButton {
    margin-left: 50%;
  }
}
@media only screen and (max-width: 415px) {
  .ticketButton {
    margin-left: 40%;
  }
}
@media only screen and (max-width: 360px) {
  .ticketButton {
    margin-left: 30%;
  }
}
@media only screen and (max-width: 330px) {
  .ticketButton {
    margin-left: 20%;
  }
}
@media only screen and (max-width: 280px) {
  .ticketButton {
    margin-left: 0;
  }
}

.both {
  clear: both !important;
}
.shareOptions {
  list-style: none;
  margin: 0;
  padding: 0;
}
.iconBox {
  width: 40px;
  padding-right: 10px;
  margin-right: 10px;
}
.icon {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  color: #fff;
  display: block;
  font-size: 24px;
  text-align: center !important;
  line-height: 40px;
  background-color: #ffffff !important;
  opacity: 0.2;
}
.icon:hover {
  opacity: 1;
}
.ic {
  color: #242424 !important;
  position: relative;
}

@media only screen and (max-width: 639px) {
  .headerTab {
    position: relative;
    width: 100%;
    top: 0;
    left: 0;
    border-radius: 0;
    padding: 8px;
  }
  .eventListHeader {
    background: none !important;
    height: auto !important;
    padding-bottom: 0;
  }
  .eventImg {
    display: block !important;
    width: 100%;
  }
  .eventTitleBox {
    text-align: left !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .eventTimeLoc {
    max-width: 100%;
    font-size: 15px !important;
  }
  .eventTitleH2 {
    font-size: 20px !important;
    line-height: 25px;
    margin-bottom: -8px !important;
  }
  /* content */
  .contentWraper {
    padding: 0 15px;
  }
  .ticketWrapper {
    right: 15px;
    left: 15px;
  }
}
@media only screen and (max-width: 880px) {
  .leftCol {
    float: left;
    width: 100%;
  }
  .rightCol {
    float: left;
    width: 100%;
  }
  .tickets {
    z-index: 9;
    padding: 10px 40px;
    box-sizing: border-box;
    float: none;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 70px;
    box-shadow: 0 -3px 4px 0 rgba(0, 0, 0, 0.2);
  }
  .ticketWrapper {
    position: absolute;
    right: 40px;
    top: 10px;
    left: 40px;
    text-align: right;
  }
  .ticketButton {
    padding: 13px 0 !important;
  }
}

/* background wrapper for modal show */

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 997;
}
.closeButton {
  position: fixed;
  top: 35px;
  left: 50%;
  margin-left: 420px;
  width: 25px;
  height: 25px;
  color: white;
  text-align: center;
  z-index: 1001;
}
.closeIcon {
  width: 100%;
  height: 100%;
}
@media only screen and (max-width: 880px) {
  .closeButton {
    top: 15px;
    right: 15px;
    margin: 0;
    left: auto;
  }
}
.overlayWindow {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.setBg {
  height: 100%;
  overflow-y: auto;
  position: relative;
  margin: 0;
  padding: 0 0 1px 0;
  background-color: transparent;
  color: #1c232b;
  font-size: 14px;
}
/* map img  iframe */
.mapImg {
  border: 0 none;
  height: 450px;
  width: 100%;
}
