:root {
  --sliderBlack: #3d424a;
  --contactUsDarkBlue: #1b2f41;
  --investBrown: #27231f;
  --menuBlack: #333131;
  --mainBlack: #242424;
  --studyBg: rgba(55, 65, 81, 1);
  --studyColor: rgba(209, 213, 219, 1);
  --secondWhite: #b32929;
  --swiper-theme-color: #807b7b !important;
}
body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: futura-pt, sans-serif;
  font-style: normal;
  font-weight: 300;
  color: rgba(243, 244, 246, 1);
}
.error {
  background-color: #f7e8e8;
}
