/* footer */
.footerBg {
  background-color: var(--mainBlack);
  border-top: 1px solid #ffffff33;
  height: 72px;
  position: absolute;
  bottom: 0;
}
.footerContainer {
  padding-left: 40px;
  padding-right: 40px;
  box-sizing: border-box;
  width: 1024px;
  margin: 0 auto;
}
@media only screen and (max-width: 1024px) {
  .footerContainer {
    width: auto;
  }
}
@media only screen and (max-width: 762px) {
  .footerContainer {
    text-align: center;
  }
}
@media only screen and (max-width: 639px) {
  .footerContainer {
    width: auto;
    margin: 0;
  }
}
.needHelp {
  opacity: 1;
  font-size: 13px;
  background-size: 22px 22px;
  line-height: 20px;
  display: inline-block;
  margin: 20px 0 0 0;
}
.questionIcon {
  font-size: 24px;
  position: relative;
  top: 4px;
  padding-right: 4px;
}
.helpLinks a {
  display: inline-block;
  padding: 0 5px;
  color: #ffffff;
  cursor: pointer;
}
