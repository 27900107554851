.ticketRow {
  font-size: 16px;
  border-top: 1px solid rgba(28, 35, 43, 0.1);
  font-weight: 600;
  clear: both;
  line-height: 20px;
}
.ticketRow:first-of-type {
  border-top: 0;
}
.ticketName {
  padding: 0;
  box-sizing: border-box;
  display: block;
  float: left;
  width: 33% !important;
}
.ticketVar {
  margin-top: 18px;
  margin-left: 8px;
  display: block;
  float: left;
  font-style: normal;
}
.ticketPrice {
  display: block;
  margin-top: 18px;
  padding: 0;
  float: left;
  box-sizing: border-box;
  text-align: center;
  width: 33% !important;
}
.EventsBorder {
  border-color: #ffffff;
  border-width: 0.2px;
}

.ticketQuantity {
  display: block;
  margin-top: 0;
  padding: 0;
  float: left;
  box-sizing: border-box;
  text-align: right;
  width: 33% !important;
}
.ticketSelect {
  width: 100%;
  max-width: 70px;
  font-size: 16px;
  background-color: #fff;
  color: #1b0702;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  border-color: rgba(28, 35, 43, 0.2);
  appearance: none;
  background-image: url(../../../assets//img/dropdown.png);
  background-size: 30px auto;
  background-position: center right;
  background-repeat: no-repeat;
  padding: 12px 30px 12px 10px;
}
.space {
  clear: left;
}
