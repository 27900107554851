.sliderBg {
  height: calc(100vh - 79.5px);
  background-color: var(--sliderBlack);
}
.sliderImg {
  width: 300px;
  height: auto;
  margin-bottom: 10px;
}
@media only screen and (max-width: 310px) {
  .sliderImg {
    width: 80%;
    margin-bottom: -10%;
  }
}
.centerBox {
  height: 100%;
  margin-top: -73px;
}
.centerBox2 {
  height: 100%;
  margin-top: -27px;
}

.sliderCaption {
  width: 41.666667%;
}
.sliderText {
  text-align: justify;
  text-align-last: center;
  text-justify: none;
  word-spacing: -1px;
  font-size: 18px !important;
}
@media only screen and (max-width: 992px) {
  .sliderText {
    text-align: justify;
    text-align-last: center;
    text-justify: none;
    margin-top: 20px;
    word-spacing: -2px;
    font-size: 16px !important;
  }
}
@media only screen and (max-width: 1280px) {
  .sliderCaption {
    width: 66.666667%;
  }
}
@media only screen and (max-width: 768px) {
  .sliderCaption {
    width: 91.666667%;
  }
}
.sliderImg {
  height: 110px;
  margin-bottom: 40px;
}
.inventImg {
  height: 265px;
  margin-bottom: -40px;
}
@media only screen and (max-height: 500px) {
  .sliderImg {
    margin-bottom: -0px;
  }
  .inventImg {
    margin-bottom: -80px;
  }
  .sliderText {
    display: none;
  }
  /* .btn {
    position: absolute;
    bottom: 20px;
  } */
}
.btn {
  transition: 0.3s;
  margin-top: 2.5rem;
  background: transparent;
  border: 1px solid #fafafa;
  border-radius: 9999px;
  color: #fafafa;
  padding: 10px 40px;
  width: max-content;
}
.btn:hover {
  background: #fafafa;
  color: var(--sliderBlack);
}
