.ContactUsBg {
  background-color: var(--contactUsDarkBlue);
  height: 100vh !important;
}
@media only screen and (max-width: 1080px) {
  .ContactUsBg {
    height: auto !important;
  }
}
.ContentHolder {
  width: 70%;
  margin: auto;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.ContentHolder > form > h1 {
  color: rgb(209, 213, 219);
  text-align: center;
  font-weight: 300;
  margin-top: 5rem;
  font-size: 1.25rem;
}
.ContentHolder > form > input {
  color: #fff;
  width: 100%;
  outline: none;
  border-bottom: 1px solid #ccc;
  margin-top: 20px;
}
.ContentHolder > form > input::-webkit-input-placeholder {
  color: #9ca3af;
  opacity: 0.25;
}
.ContentHolder > form > input::-moz-placeholder {
  opacity: 0.25;
  color: #9ca3af;
}
.ContentHolder > form > input::-ms-input-placeholder {
  opacity: 0.25;
  color: #9ca3af;
}
.ContentHolder > form > hr {
  margin-top: -5px;
}
.ErrorMessage {
  color: rgb(248, 113, 113);
  font-weight: 400;
}
.ContentHolder > form > p {
  margin-top: 80px;
  text-align: center;
  margin-bottom: 0;
}
.DataProcessing {
  color: rgb(147, 197, 253);
  cursor: pointer;
}
.Submit {
  margin: 0 auto;
  margin-top: 15px;
  padding: 7px 40px;
  font-size: 18px;
  color: #c1c1c1;
  border: 2px solid #c1c1c1;
  border-radius: 9999px;
  background-color: transparent;
}
.Submit:hover {
  background-color: #ccc;
  color: #000;
}
