var {
  font-style: normal;
}
.basketIcon {
  color: #275d9c !important;
  text-decoration: none;
  padding-right: 10px;
  font-size: 14px;
  float: right;
  font-weight: normal;
  padding-left: 10px;
  position: relative;
  top: 2px;
  cursor: pointer;
}
.basketIcon::after {
  content: "\f07a";
  font-family: "FontAwesome";
  font-weight: 900;
  position: relative;
  left: 10px;
  top: 0;
}
/* payment Box */

.paymentMethods {
  margin: 20px -800px 0 -800px;
  padding: 0 800px;
  border-top: 1px solid rgba(28, 35, 43, 0.1);
}
.cardHolder {
  margin-top: 10px;
  margin-bottom: 10px;
  max-width: 456px;
}
.payByCard {
  width: 100%;
}
.paymentForm {
  clear: right;
}
.cardElementData {
  border-radius: 4px;
  padding: 0;
  background-color: #fff;
  margin-bottom: 10px;
  position: relative;
  border: 1px solid #c9c9c9;
}
.cardElementData label {
  color: #757575;
  font-family: sans-serif;
  font-size: 14px;
  padding: 10px 10px 10px 15px;
  position: absolute;
  top: 0;
  left: 0;
}
.cardElementData input {
  font-size: 16px;
  padding: 10px 15px 10px 115px;
  border: 0;
  width: 100%;
  box-sizing: border-box;
  background: none;
}
.cardElement {
  background-color: white;
  padding: 8px 12px;
  border-radius: 4px;
  border: 1px solid #c9c9c9;
}
.cardNumElement {
  margin: 0px !important;
  padding: 0px !important;
  border: medium none !important;
  display: block !important;
  background: transparent none repeat scroll 0% 0% !important;
  position: relative !important;
  opacity: 1 !important;
}
.paymentButton {
  width: 100%;
  border-radius: 6px;
  padding: 12px 14px;
  display: inline-block;
  font-size: 16px;
  font-weight: normal;
  text-align: center;
  color: #ffffff;
  text-decoration: none;
  background-color: #4d8000;
  border: 0;
  box-shadow: none;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: 10px;
}
.cardNumInput {
  border: medium none !important;
  display: block !important;
  position: absolute !important;
  height: 1px !important;
  top: -1px !important;
  left: 0px !important;
  padding: 0px !important;
  margin: 0px !important;
  width: 100% !important;
  opacity: 0 !important;
  background: transparent none repeat scroll 0% 0% !important;
  pointer-events: none !important;
  font-size: 16px !important;
}
.elementsError {
  font-size: 12px;
  color: red;
  padding: 5px 0;
}
.sslLogo {
  margin-top: 10px;
  margin-bottom: 15px;
}
