.Item {
  margin-top: 50px;
  background: #2d2824;
  box-shadow: 0 10px 50px rgba(31, 29, 26, 0.59);
  position: relative;
  padding: 0 3vw 30px;
  height: auto;
  font-size: 18px;
  text-align: center;
  position: relative;
  z-index: 10;
  border-radius: 0.5rem;
  cursor: pointer;
}
.Item > h1 {
  font-size: 18px;
}
.Item > p {
  font-size: 0.875rem;
  height: 44px;
  overflow: hidden;
}
.Item > img {
  z-index: 20;
  position: relative;
  width: 6vw !important;
  height: auto !important;
  top: -20px;
}
@media only screen and (max-width: 1200px) {
  .Item > img {
    width: 10vw !important;
  }
}
@media only screen and (max-width: 900px) {
  .Item > img {
    width: 14vw !important;
  }
}
