.DiaccoInvestFooterBg {
  text-align: center;
  background-image: url("../../../assets/img/invest_footer.svg");
  background-size: cover;
  min-height: 450px;
  background-position: bottom;
  position: relative;
  margin-top: 80px;
}

.DiaccoInvestFooterBg > :nth-child(1) {
  width: 100%;
  position: absolute;
  bottom: 10px;
}
.DiaccoLogo {
  width: 186px;
  vertical-align: top;
  margin-right: 25px;
}
.DiaccoDetails {
  text-align: left;
  display: inline-block;
  margin-left: 25px;
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: 400;
}
@media only screen and (max-width: 612px) {
  .DiaccoDetails {
    margin: 0 auto;
  }
}
@media only screen and (max-width: 587px) {
  .DiaccoDetails {
    text-align: center;
    margin: 0 auto;
  }
}
.Logos > a > svg {
  width: 30px;
  margin: 0 15px;
}
