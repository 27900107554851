.windowHolder {
  max-width: 800px;
  margin: 0 auto;
}
.shopContainer {
  box-shadow: none !important;
  margin: 0;
  overflow: hidden;
  width: auto;
  background-color: transparent;
  padding: 0;
  padding-top: 30px;
  text-align: left;
  border-radius: 10px;
  position: relative;
  font-family: "Open Sans", "Arial", sans-serif;
}
.modalBodyWraper {
  width: 100%;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  background-color: #fff;
  border-radius: 6px;
  position: relative;
}
.modalBodyWraper p {
  margin: 0 0 10px 0;
}
.modalBodyWraper h3 {
  font-size: 18px;
  padding: 20px 0;
  clear: left;
  font-weight: 600;
  color: #1c232b;
}
.modalBody {
  padding-top: 0;
  min-height: 170px;
  position: relative;
  width: 800px;
  box-sizing: border-box;
  padding: 10px 40px;
  margin: 0 auto;
  border-bottom: 1px solid rgba(28, 35, 43, 0.1);
  overflow: hidden;
}
.modalBody a {
  color: #1c232b;
}
/* modal header */
.modalHeader {
  margin: 0 -800px 0 -800px;
  padding: 20px 800px;
  border-bottom: 1px solid rgba(28, 35, 43, 0.1);
  text-align: center;
}
.headerTitle {
  font-size: 20px;
  padding: 0 20px;
  font-weight: 600;
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
}
@media only screen and (max-width: 900px) {
  .headerTitle {
    font-size: 16px;
    white-space: normal;
  }
}
.headerTime {
  opacity: 0.7;
  font-size: 14px;
  padding: 5px 0 0 0;
  margin: 0;
  text-align: center;
  font-weight: normal;
}
.progress {
  display: inline-block;
  margin: 16px auto 0 auto;
  font-size: 12px;
  padding: 5px 4px;
  list-style-type: none;
  background-color: rgba(28, 35, 43, 0.05);
  border-radius: 15px;
}
.progress li {
  display: inline-block;
  margin: 0;
  padding: 0 10px;
}
.progress li.active {
  font-weight: bold;
  opacity: 1;
}
.mobileOnly {
  display: none;
}
.progress li::after {
  font-weight: normal;
  opacity: 1;
  border-style: solid;
  border-color: rgba(28, 35, 43, 0.6);
  border-width: 1px 1px 0 0;
  content: "";
  display: inline-block;
  left: 10px;
  position: relative;
  top: 8px;
  transform: rotate(-315deg);
  vertical-align: top;
  width: 5px;
  height: 5px;
}
.progress li:last-child::after {
  width: 0px;
  height: 0px;
}

@media only screen and (max-width: 500px) {
  .mobileOnly {
    display: inline-block;
  }
  .mobileHide {
    display: none;
  }
}
@media only screen and (max-width: 900px) {
  .windowHolder {
    width: 100%;
    max-width: 100%;
    border-radius: 0;
  }
  .shopContainer {
    background-color: transparent;
    padding-top: 0;
    border-radius: 0 !important;
  }
  .modalBodyWraper {
    border-radius: 0 !important;
  }
}

@media only screen and (max-width: 900px) {
  .modalBody {
    width: 100%;
  }
}

/* tickets form */
.ticketForm {
  position: relative;
}
.formItems {
  overflow: visible;
  margin: 0;
  clear: left;
}
.submitBox {
  border-top: 1px solid rgba(28, 35, 43, 0.1);
  position: relative;
  padding: 0 800px;
  margin: 0 -800px;
  clear: both;
  line-height: 20px;
  font-size: 14px;
  text-align: right;
}
.subtotalBox {
  display: inline-block;
  font-size: 18px;
  padding-right: 20px;
  padding-top: 8px;
}
.subtotalLabel {
  position: absolute;
  left: 800px;
  float: none;
  width: auto;
  display: inline;
  padding: 0;
  box-sizing: border-box;
}
.subtotal {
  float: none;
  display: inline;
  padding: 0;
  width: 18%;
  box-sizing: border-box;
  text-align: right;
  font-weight: bold;
}
.submitBox2 {
  width: auto;
  margin-top: 20px;
  margin-bottom: 10px;
  display: inline-block;
  float: none;
  padding: 0;
  box-sizing: border-box;
  text-align: right;
}
.submitButton {
  background-color: #4d8000;
  color: #ffffff;
  -webkit-appearance: none;
  margin-top: 0;
  padding: 10px 40px;
  border: 0 none;
  cursor: pointer;
  border-radius: 2px;
  font-size: 16px;
}

@media only screen and (max-width: 500px) {
  .submitBox {
    padding-left: 10px !important;
    padding-right: 10px !important;
    margin-left: -10px !important;
    margin-right: -10px !important;
  }
  .subtotalBox {
    font-size: 16px;
  }
  .subtotalLabel {
    left: 10px;
  }
}

.errorAlertBox {
  margin-left: -40px;
  margin-right: -40px;
  border: 0;
  min-height: 30px;
  clear: both;
  padding: 20px 40px;
  box-sizing: border-box;
}
.alertMsg {
  padding: 0pt 10px;
  color: #c91818 !important;
}

/* footer */

.footerHolder {
  width: 100%;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  background-color: transparent;
  color: #fff;
}
.footer {
  position: relative;
  width: 800px;
  box-sizing: border-box;
  padding: 10px 40px;
  margin: 0 auto;
  border-bottom: 1px solid rgba(28, 35, 43, 0.1);
  height: 110px;
}
.needHelp {
  position: absolute;
  top: 26px;
  left: 40px;
  right: 230px;
  height: 60px;
  display: block;
  float: left;
}
.footerText {
  color: #fff;
  margin: 0;
  font-size: 13px;
}
.footerText a {
  padding-right: 20px;
  padding-bottom: 5px;
  color: #fff;
  font-weight: normal !important;
  text-decoration: underline;
  cursor: pointer;
}
.powerBy {
  text-align: center;
  position: relative;
  top: 5px;
  right: 0;
  float: right;
  color: #fff;
}
.powerBy > img {
  height: 15px;
}

/* back arrow */
.backArrow {
  color: #1c232b;
  position: absolute;
  top: 20px;
  left: 20px;
  text-decoration: none;
  font-size: 20px;
}
