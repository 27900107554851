.logo {
  width: 200px !important;
  height: auto;
}
.navBg {
  box-shadow: none;
  border: 0 none;
}
.menu {
  z-index: 999;
  border-radius: 18px;
  background-color: var(--menuBlack);
  direction: ltr;
  line-height: 1.25rem;
  font-family: futura-pt, sans-serif;
}
.navItem {
  z-index: 100;
  background-color: var(--menuBlack);
  color: white !important;
}
.navItem:hover {
  background: var(--menuBlack);
  color: white !important;
}
.navItemend {
  border-radius: 0px 0px 18px 18px;
}
.navItemstart {
  border-radius: 18px 18px 0px 0px;
}
.navSpan {
  color: #817f7f;
  font-size: 0.875rem;
  font-weight: 300;
  line-height: 1.25rem;
}
.button {
  background: rgba(55, 65, 81, 1);
  color: white;
  box-shadow: none;
}
.button:hover {
  background: rgba(55, 65, 81, 1) !important;
  color: white;
  box-shadow: none;
}

@media only screen and (max-width: 376px) {
  .menuIcon {
    font-size: 12px !important;
  }
  .logo {
    width: 150px !important;
    height: auto;
  }
}
@media only screen and (max-width: 313px) {
  .logo {
    width: 120px !important;
    height: auto;
  }
}
.menuIcon {
  width: 50px;
  height: auto;
  z-index: 100;
  cursor: pointer;
}
