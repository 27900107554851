.logo {
  width: 200px !important;
  margin-top: 4rem;
  margin-left: 10vw;
  height: auto;
}
.navBg {
  box-shadow: none;
  border: 0 none;
}
.menu {
  z-index: 999;
  border-radius: 18px;
  transform: translate(-100px, 79px) !important;
  background-color: rgba(14, 18, 19, 0.84);
  color: #cf9a63 !important;
  direction: ltr;
  line-height: 1.25rem;
  font-family: futura-pt, sans-serif;
}
.menu :hover {
  background-color: transparent;
}
@media only screen and (max-width: 425px) {
  .menu {
    transform: translate(5vw, 79px) !important;
  }
}
.navItem {
  z-index: 100;
  color: #cf9a63;
}
.navItem :hover {
  z-index: 100;
  color: #cf9a63 !important;
}
.navItem > a {
  color: #cf9a63 !important;
}
.navItem > a :hover {
  color: #cf9a63 !important;
}
.navItemend {
  border-radius: 0px 0px 18px 18px;
}
.navItemstart {
  border-radius: 18px 18px 0px 0px;
}
.navSpan {
  color: #9ca3af;
  font-size: 0.875rem;
  font-weight: 300;
  line-height: 1.25rem;
}
.button {
  background: transparent !important;
  color: white;
  box-shadow: none !important;
}
.button:hover {
  background: transparent !important;
  color: white;
  box-shadow: none !important;
}
@media only screen and (max-width: 450px) {
  .button {
    position: absolute;
    top: 15px;
    right: 10px;
  }
}
@media only screen and (max-width: 376px) {
  .menuIcon {
    font-size: 12px !important;
  }
  .logo {
    width: 150px !important;
    height: auto;
  }
}
@media only screen and (max-width: 313px) {
  .logo {
    width: 120px !important;
    height: auto;
  }
}
.menuIcon {
  width: 50px;
  margin-top: 2rem;
  margin-right: 5vw;
  height: auto;
  z-index: 100;
  cursor: pointer;
}
