.investLogo {
  width: 300px;
  height: auto;
  margin-bottom: 10px;
}
.privacyText {
  text-align: left !important;
}
.privacyText > span {
  font-weight: 600;
}
.line {
  display: block;
  margin: 0.5em auto;
  border-width: 1px;
  border-style: solid;
}
.cursor {
  cursor: pointer;
}
.link {
  text-decoration: underline;
  cursor: pointer;
  text-align: center;
}
.heading {
  text-align: center;
  padding-bottom: 20px;
  margin-top: 20px;
}
.secondaryHeading {
  padding: 20px 0 20px 50px;
}

.holder > .contentUl {
  padding-left: 30px;
  list-style: none;
}
.holder > p {
  padding-left: 30px;
}
.toggle {
  cursor: pointer;
}
.toggle > span {
  /* color: #00f; */
  font-weight: 650;
  font-size: 18px;
}
.toggle > svg {
  height: 25px;
}
