.navBg {
  background: var(--sliderBlack);
  box-shadow: none;
  border: 0 none;
}
.menu {
  z-index: 999;
  border-radius: 18px;
  background-color: var(--menuBlack);
}
.navItem {
  z-index: 100;
  background-color: var(--menuBlack);
  color: white !important;
}
.navItem:hover {
  background: var(--menuBlack);
  color: white !important;
}
.navItemend {
  border-radius: 0px 0px 18px 18px;
}
.navItemstart {
  border-radius: 18px 18px 0px 0px;
}
.navSpan {
  color: #817f7f;
  font-size: 20px;
  font-weight: 100;
}
.button {
  background: transparent !important;
  box-shadow: none;
  box-shadow: none !important;
}
.button:hover {
  background: transparent !important;
  color: white;
  box-shadow: none !important;
}
.menuIcon {
  width: 50px;
  height: auto;
  z-index: 100;
  cursor: pointer;
}
.menuIcon {
  width: 30px;
  top: 13px;
  left: 20px;
  height: auto;
  z-index: 100;
  position: absolute;
  cursor: pointer;
}
