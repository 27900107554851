.eventPageBg {
  background: var(--mainBlack);
  color: #ffffff;
  min-height: 100% !important;
  height: auto !important;
  padding-bottom: 60vw;
}
.eventDetails {
  word-break: break-word;
}
.eventBox {
  min-height: 100% !important;
  height: auto !important;
}
.eventBox header {
  margin-bottom: 30px;
  padding-top: 96px;
  padding-bottom: 10px;
  text-align: left;
  width: 100%;
  background-color: #242424 !important;
}
.eventList {
  width: 100% !important;
}
.container {
  padding-left: 40px;
  padding-right: 40px;
  box-sizing: border-box;
  width: 1024px;
  margin: 0 auto;
}
.home {
  float: right;
  cursor: pointer;
  width: auto;
  position: relative;
  top: 30px;
  left: 0px;
  font-size: 23px;
}
.home > img {
  height: 35px;
}
@media only screen and (max-width: 1100px) {
  .home {
    left: 6px;
  }
}
@media only screen and (max-width: 1800px) {
  .home {
    left: 15px;
  }
}
@media only screen and (max-width: 1060px) {
  .home {
    left: 25px;
  }
}
@media only screen and (max-width: 1046px) {
  .home {
    left: -40px;
  }
}
@media only screen and (max-width: 855px) {
  .home {
    left: -38px;
  }
}
@media only screen and (max-width: 835px) {
  .home {
    left: -35px;
  }
}
@media only screen and (max-width: 810px) {
  .home {
    left: -26px;
  }
}
@media only screen and (max-width: 770px) {
  .home {
    left: -16px;
  }
}
@media only screen and (max-width: 767px) {
  .home {
    left: -40px;
  }
}
@media only screen and (max-width: 741px) {
  .home {
    left: -40px;
  }
}
@media only screen and (max-width: 639px) {
  .home {
    left: -10px;
  }
}

.dateBox {
  text-align: center;
  font-size: 19px;
  border: 1px solid #fff;
  font-weight: 500;
  width: 110px;
  height: 110px;
  /* margin-left: 12px; */
  position: relative;
  left: 12px;
}

.dateBox > p {
  width: 100%;
}
.dateBox > :nth-child(1) {
  padding-top: 13px;
}
.dateBox > :nth-child(2) {
  padding-top: 0px;
}
@media only screen and (max-width: 1080px) {
  .dateBox > :nth-child(1) {
    padding-top: 1vw;
  }
}
@media only screen and (max-width: 1046px) {
  .container {
    width: auto;
  }
}
@media only screen and (max-width: 639px) {
  .container {
    padding: 10px;
  }
}
.eventImg {
  width: 110px;
  height: 110px;
  margin: 0;
}
.eventHeader {
  background-image: url(../../assets/img/events-R.png);
  background-position: -61px center;
  background-repeat: no-repeat;
  background-size: 320px;
  height: 100px;
}

@media only screen and (max-width: 1100px) {
  .eventHeader {
    background-position: -61px center;
  }
}
@media only screen and (max-width: 1090px) {
  .eventHeader {
    background-position: -55px center;
  }
}
@media only screen and (max-width: 1080px) {
  .eventHeader {
    background-position: -50px center;
  }
}
@media only screen and (max-width: 1070px) {
  .eventHeader {
    background-position: -45px center;
  }
}
@media only screen and (max-width: 1060px) {
  .eventHeader {
    background-position: -40px center;
  }
}
@media only screen and (max-width: 1050px) {
  .eventHeader {
    background-position: -40px center;
  }
}
@media only screen and (max-width: 1046px) {
  .eventHeader {
    background-position: -20px center;
  }
}
@media only screen and (max-width: 640px) {
  .eventHeader {
    background-position: -53px center;
  }
}

@media only screen and (max-width: 1100px) {
  .eventDetails {
    margin-right: 1%;
  }
}

@media only screen and (max-width: 768px) {
  .eventDetails {
    width: 65% !important;
    margin-right: 6%;
  }
}
@media only screen and (max-width: 740px) {
  .eventDetails {
    width: 80% !important;
    margin-right: -9%;
  }
  .btnBox {
    width: 100%;
    margin: auto;
    margin-top: 10px !important;
  }
  .eventImg {
    width: 90px;
    height: 90px;
  }
  .dateBox {
    width: 90px;
    height: 90px;
  }
  .eventBtn {
    width: 100%;
    font-size: 15px !important;
    padding: 10px !important;
  }
  .btnBox {
    margin-top: 12px;
  }
}
@media only screen and (max-width: 634px) {
  .eventDetails {
    margin-right: -8%;
  }
}

@media only screen and (max-width: 576px) {
  .eventDetails {
    width: 70% !important;
    margin-right: 10%;
  }
}
@media only screen and (max-width: 515px) {
  .eventDetails {
    margin-right: 7%;
  }
}
@media only screen and (max-width: 440px) {
  .eventDetails {
    width: 65% !important;
    margin-right: 8%;
  }
}
@media only screen and (max-width: 380px) {
  .eventDetails {
    margin-right: 5%;
  }
}
@media only screen and (max-width: 341px) {
  .eventDetails {
    width: 60% !important;
    margin-right: 4%;
  }
}
@media only screen and (max-width: 291px) {
  .eventDetails {
    margin-right: 1%;
  }
}
@media only screen and (max-width: 262px) {
  .eventDetails {
    margin-right: -3%;
  }
}
@media only screen and (max-width: 234px) {
  .eventDetails {
    width: 50% !important;
    margin-right: -3%;
  }
}
/* 
@media only screen and (max-width: 430px) {
  .eventDetails {
    width: 65% !important;
    margin-right: -1%;
  }
}
@media only screen and (max-width: 384px) {
  .eventDetails {
    width: 60% !important;
    margin-right: 5%;
  }
}
@media only screen and (max-width: 340px) {
  .eventDetails {
    width: 60% !important;
    margin-right: 1%;
  }
} */
@media only screen and (min-width: 1100px) {
  .eventDetails {
    margin-right: 40px;
  }
}
.eventList a {
  text-decoration: none;
  color: #ffffff;
}
.eventDetails {
  min-height: 108px;
}
.eventName {
  color: inherit;
  font-weight: 600;
  font-size: 24px;
  padding: 0;
  margin: 0 0 10px 0;
  word-break: break-word;
}
.eventTime {
  color: inherit;
  font-size: 16px;
  font-weight: 400;
  padding: 0;
  margin: 0;
  line-height: 1.87;
  overflow: hidden;
  opacity: 0.8;
  word-break: break-word;
}
.eventLocation {
  color: inherit;
  font-size: 16px;
  font-weight: 400;
  padding: 0;
  margin: 0;
  line-height: 1.87;
  overflow: hidden;
  opacity: 0.8;
  word-break: break-word;
}
.eventBtn {
  min-width: 175px;
  background-color: #af091d !important;
  color: #ffffff !important;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  border-width: 0;
  border-radius: 4px;
  padding: 14px;
  box-sizing: border-box;
}
.EventsBorder {
  border-color: #ffffff;
  border-width: 0.2px;
}
